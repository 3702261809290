import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Loader } from "../../Components/common/loader";
import { Auth } from 'aws-amplify';
import { toast } from "react-toastify";
import OtpInput from 'react-otp-input';


export default function OTPscreen({formik, focusField, resendingCode, counter, submitLoader, setSubmitLoader, AuthUser, setAuthUser, timer, setCookie, setCookiesInStorage, redirectUrl, firstLogin, setHasChallenge,  setUserValues, setResendingCode, initiateChallenge, userValues }) {


  const defaultAttempts = 2
  const [QRcode, setQRcode] = useState('');
  const [count, setCount]= useState(0);
  const [attempts, setAttempts] = useState(2);
 

  const navigate = useNavigate();

  const handleQRsubmit = async () => {
    setSubmitLoader(true);
    const challengeResponse = QRcode;
    const user = AuthUser;
    Auth.sendCustomChallengeAnswer(user, challengeResponse)
      .then(userData => {
        if (!userData.signInUserSession) {
          setAuthUser(userData);
          setSubmitLoader(false);
          toast.error(`Invalid code. ${attempts} attempts left.`, { toastId: "err01" });
          setQRcode("");
          setAttempts(attempts - 1)
        } else {
          setCookiesInStorage(userData, setCookie, redirectUrl, firstLogin, navigate);
        }
      })
      .catch(err => {
        console.log(err);
        if (err.message === "Incorrect username or password." || err.message === "Invalid session for the user.") {
          setSubmitLoader(false);
          setHasChallenge(false);
          setCount(defaultAttempts);
          setUserValues(false);
          toast.error(`Invalid code. Please login again.`, { toastId: "err01" });
        }
      });
  }

  const sendCodeAgain = () => {
    setSubmitLoader(true);
    setResendingCode(true);
    setCount(count + 1);
    initiateChallenge(userValues);
  }

  const handleQR = (value) => {
    setQRcode(value);
  };

    return (
        <>
          <div className="col-md-10 col-lg-12 col-xl-11 px-4 form text-center ">
               
               <div className="form_header mb-5">
                <h4 className="mb-5">Verify your identity</h4>
                <h6 className="font-rubik">Enter the code we've sent to {AuthUser.email}</h6>
              </div>
              <form className="font-rubik">  

                <div className="mb-4 otp_inputs d-flex justify-content-center align-items-center ">
                        <OtpInput
                          className={`form-control cstm_input`}
                          inputType={"tel"}
                          value={QRcode}
                          onChange={handleQR}
                          numInputs={6}
                          inputStyle={{ maxHeight: 60, maxWidth: 60, width: "2.8rem", outline: 0 }}
                          errorStyle={{ border: "solid 1px red" }}
                          renderSeparator={<span> </span>}
                          renderInput={(props) => <input {...props} />}
                        />
                      </div>
                          
                   <div className="row justify-content-end align-items-center mb-3">
                    {count > 2 ? "" : 
                    <>{resendingCode 
                      ? <div className="col-12 text-end" >Sending code again...</div> 
                      : timer 
                        ? count < 3 
                          ? <div className="col-12 text-center" >You can resend code again after {counter} seconds.</div> 
                          : '' 
                        :  
                        <div className="col-12 text-end" >
                            {<Link to="" className="small_text mb-1" onClick={sendCodeAgain}>
                              Resend Code
                            </Link>}
                        </div>}
                    </>}
                </div>
              </form>
              {submitLoader ? (
                <button disabled={true} className="btn btn_black w-100">
                  <Loader style={{ width: "1rem", height: "1rem" }} />
                </button>
              ) : (
                <button
                    type="button"
                    className="btn btn_black w-100 "
                    onClick={handleQRsubmit}
                  >
                    Submit
              </button>
              )}
            </div>
        </>
    );
}