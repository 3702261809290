import React, { Suspense } from "react";
import { ToastContainer } from "react-toastify";
import MainRouter from "./MainRouter/MainRouter";
import "react-toastify/dist/ReactToastify.css";
import { CookiesProvider } from 'react-cookie';
import { Loader } from "./Components/common/loader";

import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';

import { BrowserRouter } from "react-router-dom";

// Configure Amplify in index file or root file
Amplify.configure({
  Auth: {
      region: awsExports.REGION,
      userPoolId: awsExports.USER_POOL_ID,
      userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID
  },
  authenticationFlowType: 'CUSTOM_AUTH'
})

function App() {
  return (
    <CookiesProvider>
      <Suspense fallback={<div className="d-flex justify-content-center align-items-center" style={{height: "100vh"}}><Loader /></div>} >
      <BrowserRouter>
        <MainRouter />
      </BrowserRouter>
      <ToastContainer />
      </Suspense>
    </CookiesProvider>
  );
}

export default App
