import {v4 as uuidv4} from "uuid";
// import { useNavigate } from "react-router-dom";
import { DOMAIN } from "./baseurl";
import { toast } from "react-toastify";
import { Auth } from "aws-amplify";

export const getCognitoToken = () => localStorage.getItem("token")

export const removeToken = () => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
};

export const isAuth = () => Boolean(getCognitoToken());
export const getLastElementId = () => {
  const path = window.location.pathname.split("/");
  return path[path.length - 1];
};

export const get2LastElementId = () => {
  const path = window.location.pathname.split("/");
  return path[path.length - 2];
};

export const reactSelectCustomStyle = {
  control: (provided, state) => ({
    ...provided,
    minWidth:200,
    boxShadow: "none",
    borderColor: state.isFocused ? "rgb(33,33,33)" : "#ced4da",
    "&:hover": {
      borderColor: state.isFocused ? "rgb(33,33,33)" : "#ced4da",
    },
  }),
};

export const bytesToSize = bytes => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export const getRandomNumber = () => uuidv4()

export const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    background: "rgba(161,161,161,0.75)"
  }
};

 export const setCookiesInStorage = async (userData, setCookie, redirectUrl, firstLogin,navigate) => {
  const session = await Auth.currentSession();
  const token = session.getAccessToken().getJwtToken();
  const idToken = session.getIdToken().getJwtToken();
  const refreshToken = session.getRefreshToken().getToken();
  // console.log(userData);
  setCookie("token", token, { path: "/", domain: DOMAIN })
  setCookie("refreshToken", refreshToken, { path: "/", domain: DOMAIN })
  setCookie("idToken", idToken, { path: "/", domain: DOMAIN })
  toast.success("Login success.", { position: "top-right", autoClose: 3000 })
  
  if (redirectUrl && !firstLogin) {
    window.location.href = redirectUrl
  } else if (firstLogin && userData.attributes["custom:MFA"] === "optional"){
    navigate("/authentication");
  } else {
    navigate("/")
  }
}