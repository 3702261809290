import * as yup from 'yup';
export const ResetPsswordValidationSchema = yup.object({   
    email: yup
      .string('Enter your email address')
      .email('Enter valid email address')
      .required('Email address is required'),
    password: yup
     .string('Enter your password')
    .min(8, 'Password should be at least 8 characters')
    .matches(/[A-Z]/, 'Password should contain at least 1 uppercase letter')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password should contain at least 1 special character')
    .required('Password is required'),
    confirmPassword: yup.string("Confirm password required")
    .oneOf([yup.ref('password'), null], 'Passwords do not match')
    .required('Confirm password required')
});
  