import React, { useEffect } from "react";
import { Auth } from "aws-amplify";
import {useNavigate, useSearchParams } from "react-router-dom";
import {
    AUTH_UI_BASE_URL,
    DOMAIN
  } from "../../utils/baseurl";
import { LoaderDark } from "../../Components/common/loader";

export default function Logout() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirectUrl");

  useEffect(() => {
    const removeToken = async () => {
      // Code to remove token and cookies
      await Auth.signOut();
  
      const cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = `${name}=; path=/; domain=${DOMAIN}; expires=${new Date().toUTCString()}`;
      }
    };
    
    removeToken();
    if(redirectUrl) {
      navigate(`/sign-in?redirectUrl=${redirectUrl}`);
    } else {
      navigate(`/sign-in`);
    }
  }, []);
  
  return (
    <div className="col-lg-5 px-3 position-relative text-center">
    <LoaderDark />
    </div>
  )
  };


  

