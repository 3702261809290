import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Loader, LoaderDark } from "../../Components/common/loader";
import { InputErrorMessage } from "../../Components/errorMessages.js/index.js";
import { ResetPsswordValidationSchema } from "./validation";
import { cognitoPostApiWithoutToken } from "../../apis/api-interface";
import { useCookies } from "react-cookie";
import { DOMAIN } from "../../utils/baseurl";
import SignInLeftComp from "../CommonComp/signInLeftComp";
import { Auth } from "aws-amplify";
import { Tooltip } from 'react-tooltip';
import ReactDOMServer from 'react-dom/server';
import { setCookiesInStorage } from "../../utils/helperFunctions";

export default function ResetPassword() {
  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get("email");
  const token = searchParams.get("token");
  const actype = searchParams.get("actype");
  // const redirectUrl = searchParams.get("redirectUrl");
  const [cookies, setCookie] = useCookies();
  
  const navigate = useNavigate();
  
  const focusField = useRef(null);
  
  const [loading, setLoading] = useState(false);
  const [mainLoader, setMainLoader] = useState(true);
  const [showExpiredScreen, setShowExpiredScreen] = useState(false);
  
  const firstLogin = null;
  const redirectUrl = false;

  useEffect(() => {

    cognitoPostApiWithoutToken("/cognito/verify-jwt", { token })
      .then(res => {
        console.log("response", res);
        setMainLoader(false);
      })
      .catch(e => {
        console.log(e)
        setMainLoader(false);
        setShowExpiredScreen(true);
      })
  }, [])

  const formik = useFormik({
    initialValues: {
      email,
      password: "",
    },
    validationSchema: ResetPsswordValidationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        if (!email) {
          toast.error("Email not found.", { toastId: "err01" });
          return;
        }
        if (token && values?.email && values?.password && values?.password === values?.confirmPassword) {
          const res = await cognitoPostApiWithoutToken("/user/reset-password", { token, values })
          if (res?.data?.status) {
            const code = res?.data?.code;
            const newToken = res?.data?.token
            const refreshToken = res?.data?.refreshToken
            const idToken = res?.data?.idToken
            toast.success("Password updated.", { position: "top-right", autoClose: 3000 });
            // console.log(res.data)
            if (token && refreshToken) {
              setCookie("token", newToken, { path: "/", domain: DOMAIN })
              setCookie("refreshToken", refreshToken, { path: "/", domain: DOMAIN })
              setCookie("idToken", idToken, { path: "/", domain: DOMAIN })
            }
            if (redirectUrl) {
              window.location.href = redirectUrl
            } else if (code === "CredentialsUpdated") {
              initiateChallenge(values);
            } else {
              navigate("/")
            }

          } else {
            toast.error(res?.data?.msg || res?.data?.message || "Something went wrong", { toastId: "err01" });
          }
        }
        setLoading(false);
      } catch (error) {
        toast.error(error?.response?.data?.message || "Something went wrong.", { toastId: "err01" });
        setLoading(false);
      }
    },
  });

  const initiateChallenge = async (values) => {
    try {
      let username = values.email;
      let password = values.password;
      const user = await Auth.signIn(username, password);
      user.email = values.email;
      if(user?.attributes?.["custom:MFA"] === "inactive"){
        setCookiesInStorage(user, setCookie, redirectUrl, firstLogin, navigate);
      }
      navigate("/authentication", {state:  {userValues: values}})
    } catch (e) {
      toast.error(e?.message || "Something went wrong.", { toastId: "err01" });
    }
  }

  const handleChange = (e) => {
    e.preventDefault();
    formik.setFieldTouched(e.target.name, false, false)
    formik.handleChange(e);
  };

  useEffect(() => {
    if (focusField.current) {
      focusField.current.focus();
    }
  }, []);

  useEffect(() => {
    if (!email || !token) {
      navigate("/")
    }
  }, [email, token])

  return (
    <>
      

        {mainLoader ?
          <LoaderDark />
          : showExpiredScreen
            ?
            <div className='col-lg-5 px-3 position-relative signup_form '>
              {/* <h4>Your link has expired. Please attempt to reset your password again.</h4>
              <button onClick={() => navigate("/")}>Go to Login</button> */}

              <div className='row justify-content-center align-items-center loginform_wrapper'>
                <div className=' col-md-10 col-lg-12 col-xl-11 px-4 form go_to_login_form'>
                  <div className='form_header mb-5'>
                    <h6 className='font-rubik mb-3'>
                    Your link has expired. Please attempt to reset your password again.
                    </h6>
                  </div>

                  {loading ? (
                    <button disabled={true} className='btn btn_black w-100'>
                      <Loader style={{ width: "1rem", height: "1rem" }} />
                    </button>
                  ) : (
                    <button onClick={() => navigate("/")} className='btn btn_black w-100'>Go to Login</button>
                  )}
                </div>
              </div>
            </div>
            :
            <div className='col-lg-5 px-3 position-relative signup_form '>
              <div className='row justify-content-center align-items-start'>
                <div className='col-md-10 col-lg-12 col-xl-11 px-4 form loginform_wrapper'>
                  <div className='form_header mb-5'>
                    <h4 className='font-rubik mb-3'>Reset Password</h4>
                    <h6 className='font-rubik mb-3'>
                      Back to login?{" "}
                      <Link to='/sign-in' className='ms-1'>
                        Sign in
                      </Link>
                    </h6>
                    {/* <p className="mb-3">Enter the email address associated with your account and we will send the instruction to reset the password </p> */}
                  </div>
                  <form className='font-rubik py-3' onSubmit={formik.handleSubmit}>
                    <div className='mb-4'>
                      <label htmlFor='email' className='form-label'>
                        Email
                      </label>
                      <input
                        type='email'
                        className='form-control cstm_input'
                        placeholder='Enter your email'
                        aria-describedby='emailHelp'
                        id='email'
                        name='email'
                        value={formik.values.email}
                        ref={focusField}
                        disabled={true}
                      />
                      <InputErrorMessage error={formik.touched.email && formik.errors.email} marginBottom={-15} />
                    </div>
                    <div className='mb-4'>
                      <label htmlFor='exampleInputPassword1' className='form-label'>
                        Password 
                      </label> 
                      
                      <i
                        data-tooltip-id="my-tooltip" 
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(<div>Password should meet the following criteria:<br/><br/>&#8226; 1 capital letter<br/>&#8226; 1 special character<br/>&#8226; Atleast 8 characters long</div>)}
                        className="bi bi-info-circle ms-2"
                      ></i>
                      <Tooltip id="my-tooltip" />
     
                      <input
                        type='password'
                        placeholder='Enter your password'
                        id='password'
                        onChange={handleChange}
                        disabled={loading}
                        className={`form-control cstm_input ${formik.touched.password && Boolean(formik.errors.password) ? "error" : ""}`}
                        name='password'
                        value={formik.values.password}
                      />
                      <InputErrorMessage error={formik.touched.password && formik.errors.password} marginBottom={-15} />
                    </div>
                    <div className='mb-4'>
                      <label htmlFor='exampleInputPassword1' className='form-label'>
                        Confirm Password
                      </label>
                      <input
                        type='password'
                        placeholder='Enter your password'
                        id='confirmPassword'
                        onChange={handleChange}
                        disabled={loading}
                        className={`form-control cstm_input ${formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword) ? "error" : ""}`}
                        name='confirmPassword'
                        value={formik.values.confirmPassword}
                      />
                      <InputErrorMessage error={formik.touched.confirmPassword && formik.errors.confirmPassword} marginBottom={-15} />
                    </div>
                    {loading ? (
                      <button disabled={true} className='btn btn_black w-100'>
                        <Loader style={{ width: "1rem", height: "1rem" }} />
                      </button>
                    ) : (
                      <button type='submit' id='kt_sign_in_submit' className='btn btn_black w-100'>
                        Submit
                      </button>
                    )}
                  </form>
                </div>
              </div>
              <div className='copyright_footer position-absolute bottom-0 text-center'>
                <p>Copyright © 2015-2023 Inflowcare, LLC. All rights reserved.</p>
              </div>
            </div>
        }
      
    </>
  );
}
