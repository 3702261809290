import axios from "axios";
import { API_BASE_URL, COGNITO_API_BASE_URL } from "../utils/baseurl";
import { getCognitoToken } from "../utils/helperFunctions";

export const postApiWithoutToken = (path, data) => {
  return axios.post(API_BASE_URL + path, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const cognitoPostApiWithoutToken = (path, data) => {
  return axios.post(COGNITO_API_BASE_URL + path, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const cognitoPostApiWithToken = async (path, data) => {
  const token = getCognitoToken()
  return axios.post(COGNITO_API_BASE_URL + path, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const putApiWithoutToken = (path, data) => {
  return axios.put(API_BASE_URL + path, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getApiWithoutToken = (path) => {
  return axios.get(API_BASE_URL + path, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const cognitoGetApiWithoutToken = (path) => {
  return axios.get(COGNITO_API_BASE_URL + path, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const postApi = async (path, data) => {
  const token = getCognitoToken()
  return axios.post(API_BASE_URL + path, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getApi = async (path, data) => {
  const token = getCognitoToken()
  return axios.get(API_BASE_URL + path, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })  
};

export const deleteApi = async (path, data) => {
  const token = getCognitoToken()
  return axios.delete(API_BASE_URL + path, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const putApi = async (path, data) => {
  const token = getCognitoToken()
  return axios.put(API_BASE_URL + path, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fileUpload = async (path, data) => {
  const token = getCognitoToken()
 
  return axios.post(API_BASE_URL + path, data, {
    headers: {
    
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};