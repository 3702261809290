import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import LogoutIcon from "../../Assets/images/logout_icon.png";
import {
  APP_BASE_URL,
  MONOLITH_BASE_URL,
  AUTH_UI_BASE_URL,
  DOMAIN
} from "../../utils/baseurl";
import { cognitoPostApiWithoutToken } from "../../apis/api-interface";
import { toast } from "react-toastify";
import SignInLeftComp from "../CommonComp/signInLeftComp";
import { Auth } from "aws-amplify";
import { LoaderDark } from "../../Components/common/loader";


export default function Homepage( ) {
  const [searchParams, setSearchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirectUrl");
  const origin = searchParams.get("origin");
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies();
  const [loader, setLoader] = useState(true);

  useEffect(() => {
      if (!(cookies?.token && cookies?.refreshToken)) {
          if (redirectUrl && origin) {
              navigate(`/sign-in?redirectUrl=${redirectUrl}&origin=${origin}`)
              setLoader(false);
          } else {
              navigate(`/sign-in`)
              setLoader(false);
          }
      } else {
        // setLoader(false);
        window.location.href = MONOLITH_BASE_URL;
      }

  }, [])

  const logout = async () => {
    navigate(`/logout`)
  };

  return (
    <>
     {loader ? <div className="col-lg-5 px-3 position-relative text-center">
     <LoaderDark />
     </div> :   <div className="col-lg-5 px-3 position-relative">
        <div className="row justify-content-center align-items-start ">
          <div className="col-9 px-4  my-5 loginform_wrapper">
            <form className="font-rubik">
              <div className="row  mb-5">
                <div className="form_header mb-3 text-center">
                  <h4 className="font-rubik">Inflowcare App</h4>
                </div>
                <div className="d-flex justify-content-center mb-5">
                  <a className="w-100" href={MONOLITH_BASE_URL}>
                  <button type="button" className="btn btn_black w-100">Visit</button>
                  </a>
                </div>
              </div>
              {/* <div className="row mb-3">
                  <div className="form_header mb-3  text-center">
                  <h4 className="font-rubik">Inflowcare Training App</h4>
                  </div>
                  <div className="d-flex justify-content-center mb-3">
                  <a className="w-100" href={APP_BASE_URL}>
                      <button className="btn btn_black w-100">Visit</button>
                  </a>
                  </div>
              </div> */}
            </form>
          </div>
        </div>

        <div className="logout_btn position-absolute text-center">
          <div className="circle" onClick={logout}>
              <img src={LogoutIcon} className="img-fluid"/>
          </div>
        </div>

        <div className="copyright_footer position-absolute bottom-0 text-center">
          <p>Copyright © 2015-2023 Inflowcare, LLC. All rights reserved.</p>
        </div>
      </div>}
     
       
    </>
  );
}
