export const Loader = ({ style }) => {
  return (
    <div className="spinner-border text-white" style={style} role="status"></div>
  );
};

export const LoaderSmallWhite = ({ style }) => {
  return (
    <div className="spinner-border spinner-border-sm text-light" style={style} role="status"></div>
  );
};

export const LoaderDark = ({ style }) => {
  return (
    <div className="spinner-border text-dark" style={style} role="status"></div>
  );
};
