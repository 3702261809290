import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Authentication from "../Pages/Authentication";
import Logout from "../Pages/Logout";
import SignInLeftComp from "../Pages/CommonComp/signInLeftComp";
import Homepage from "../Pages/Homepage";
import ResetPassword from "../Pages/Reset-Password";
import { useCookies } from "react-cookie";
import { MONOLITH_BASE_URL } from "../utils/baseurl";
import { LoaderDark } from "../Components/common/loader";


const SignIn = React.lazy(() => import("../Pages/Sign-in/index"));
const ForgotPassword = React.lazy(() => import("../Pages/Forgot-Password"));
// const ResetPassword = React.lazy(() => import("../Pages/Reset-Password"));
// const Homepage = React.lazy(() => import("../Pages/Homepage"));
// const AddMFA = React.lazy(() => import("../Pages/MFA"));

function MainRouter() {
  const [cookies, setCookie] = useCookies();
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const logoutURL = window.location.pathname === "/logout";
    if (cookies?.token && cookies?.refreshToken && !logoutURL) {
      window.location.href = MONOLITH_BASE_URL;
    } else {
      setLoader(false);
    }
  }, []);

  return (
    loader ? <div className="col-lg-12 text-center" style={{marginTop: "15%"}}><LoaderDark /></div> :
      <div className="row mx-0 ">
        <SignInLeftComp />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/authentication" element={<Authentication />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
  );
}
export default MainRouter;
