import React, { useEffect, useState } from "react";
import SignInLeftComp from "../CommonComp/signInLeftComp";
import CheckIcon from "../../Assets/images/check_icon.svg";
import { Auth } from "aws-amplify";
import { Link, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Loader, LoaderDark } from "../../Components/common/loader";
import { cognitoPostApiWithToken } from "../../apis/api-interface";
import { getCognitoToken } from "../../utils/helperFunctions";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { DOMAIN } from "../../utils/baseurl";
import { setCookiesInStorage } from "../../utils/helperFunctions"
import OTPscreen from "../CommonComp/OTPscreen";

export default function Authentication(props) {
  const [cookies, setCookie] = useCookies();
  const [loader, setLoader] = useState(true);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [isCheck, setCheck] = useState(true);
  const [userMFA, setUserMFA] = useState('');
  const [setupMFA, setSetupMFA] = useState(false);
  const [hasChallenge, setHasChallenge] = useState(false);
  const [resendingCode, setResendingCode] = useState(false);
  const [timer, setTimer] = useState(false);
  const [counter, setCounter] = useState(0);
  const [AuthUser, setAuthUser] = useState('');
  const [userValues, setUserValues]= useState(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const location = useLocation();
  const firstLogin = null;
  const redirectUrl= searchParams.get("redirectUrl");;

  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
    .then((user) => {
      if(user.attributes["custom:MFA"] !== "optional" && user.attributes["custom:MFA"] !== "required"){
        navigate("/sign-in")
      } else {
        const userMFA = user.attributes["custom:MFA"]
        setUserMFA(userMFA);
      }
      setLoader(false)
    })
    .catch((err) => {
      console.log(err);
      navigate("/sign-in")
    });
  }, [])

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  
  const initiateTimer = () => {
    setTimer(true);
    setCounter(30);
    setTimeout(() => {
      setTimer(false)
    }, 30000)
  }

  const initiateChallenge = async (values) => {
    try {
      let username = values.email;
      let password = values.password;
      const user = await Auth.signIn(username, password);
      user.email = values.email;
      setAuthUser(user);
      setUserValues(values);

      if (user.challengeName === 'CUSTOM_CHALLENGE') {
        setHasChallenge(true);
        initiateTimer();
        toast.success("OTP send successfully. Please check your email." , {position: "top-right", autoClose: 3000})
      } else {
        setCookiesInStorage(user, setCookie, redirectUrl, firstLogin,navigate);
      }
      setSubmitLoader(false);
      setResendingCode(false);
    } catch (e) {
      setSubmitLoader(false);
      toast.error(e?.message || "Something went wrong.", { toastId: "err01" });
    }
  }


  const handleSubmit = async () => {
    try {
      setSubmitLoader(true);
      const session = await Auth.currentSession();
      const token = session.getAccessToken().getJwtToken();
      const res = await cognitoPostApiWithToken("/user/update-user", {mfa: isCheck ? "email" : "inactive", token});
      toast.success("MFA updated.", { position: "top-right", autoClose: 3000 });
      if(isCheck){
        initiateChallenge(location.state.userValues)
      } else {
        initiateChallenge(location.state.userValues)
      }
    } catch (e){
      toast.error(`Something went wrong. Please try again.`, { toastId: "err01" });
      setSubmitLoader(false);
    }
  }

  const handleSkip = (e) => {
    e.preventDefault(); 
    setCookiesInStorage(null, setCookie, redirectUrl, firstLogin, navigate);
  }

  const handleCheckbox = () => {
    isCheck ? setCheck(false) : setCheck(true);
  }

  const disableButton = userMFA === "required" && !isCheck;
  const isMFARequired = userMFA === "required";

  return (
    <>
      
      {loader ? 
        <div className="col-lg-5 text-center">
          <LoaderDark />
        </div>
        : 
        <div className='col-lg-5 px-2 position-relative  '>
          <div className='row justify-content-center align-items-center mx-0 signup_form '>
          {
            setupMFA || hasChallenge ? (<OTPscreen {...{resendingCode, counter, submitLoader, setSubmitLoader, AuthUser, setAuthUser, timer, setCookie, setCookiesInStorage , redirectUrl, firstLogin, setHasChallenge,  setUserValues, setResendingCode, initiateChallenge, userValues}} />)
              : (
              <div >
                <div >
                  <div className='col-md-10 col-lg-12 col-xl-11 px-4 form loginform_wrapper'>
                    <div className='form_header mb-5'>
                      <h4 className='font-rubik mb-3'>Multi-Factor Authentication</h4>
                      <h6 className='font-rubik mb-3'>Protect your account with multi-factor Authentication </h6>
                      <p className='mb-3'>Each time you sign in to your segment account, you'll need your password and verification code. </p>
                    </div>
                    <div className='font-rubik py-3 authentication_wrapper cstm_scroll'>
                      <p>Two-Factor Authentication options</p>
                      <div className='text_msg_card_wrappper mb-4'>
                        <div class='card'>
                          <div class='card-body'>
                            <div className='d-flex justify-content-between'>
                              <div className='msg'>
                                <p className='mb-0 msg_heading'>Email</p>
                                <p className='mb-0'>Use your email to receive security codes.</p>
                              </div>
                              <div className='toggle_btn'>
                                <div class='form-check form-switch'>
                                  <input 
                                    class='form-check-input' 
                                    type='checkbox' 
                                    id='flexSwitchCheckChecked' 
                                    checked={isCheck} 
                                    onChange={handleCheckbox}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class='mb-4 bottm_text'>
                        <p className='text-end'>
                          {!isMFARequired && <Link to='' onClick={handleSkip}>Skip Now</Link>}
                        </p>
                      </div>
                      {submitLoader ? (
                        <button disabled={true} className="btn btn_black w-100">
                          <Loader style={{ width: "1rem", height: "1rem" }} />
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn_black w-100 "
                          onClick={handleSubmit}
                          disabled={disableButton}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                    
                  </div>
                </div>
                <div className='copyright_footer position-absolute bottom-0 text-center'>
                  <p>Copyright © 2015-2023 Inflowcare, LLC. All rights reserved.</p>
                </div>
              </div>)
          }
        </div>
        </div>
      }
    </>
  );
}
